/*
Using formula solid
$primary: #BE3030;
$home: #212121;
$away: #BE3030;
$cl: #FFFFFF;
$base: #373737;
*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext');

.sr-bb {
	background: #373737;
	font-family: Roboto, Noto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	text-align: left;
}

.sr-bb.sr-rtl {
	text-align: right;
}

.sr-bb .sr-bb {
	background: none;
}

.sr-bb .srt-base-1 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-1-win {
	background-color: transparent;
	border-color: #212121;
	color: #212121;
}

.sr-bb .srt-base-1-draw {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 40%);
	color: rgba(255, 255, 255, 40%);
}

.sr-bb .srt-base-1-lose {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active {
	background-color: rgba(190, 48, 48, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-1-is-active-2 {
	background-color: #4b4b4b;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-1-is-hoverable:hover {
	background-color: rgba(190, 48, 48, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-1-primary {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-home-1 {
	background-color: transparent;
	border-color: #212121;
	color: #212121;
}

.sr-bb .srt-base-1-away-1 {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-home-2 {
	background-color: transparent;
	border-color: #d98787;
	color: #212121;
}

.sr-bb .srt-base-1-away-2 {
	background-color: transparent;
	border-color: #d98787;
	color: #be3030;
}

.sr-bb .srt-base-1-home-3 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 12%);
	color: #212121;
}

.sr-bb .srt-base-1-away-3 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 12%);
	color: #be3030;
}

.sr-bb .srt-base-1-home-4 {
	background-color: transparent;
	border-color: #282828;
	color: #282828;
}

.sr-bb .srt-base-1-away-4 {
	background-color: transparent;
	border-color: #963232;
	color: #963232;
}

.sr-bb .srt-base-1-home-5 {
	background-color: transparent;
	border-color: #6f6f6f;
	color: #6f6f6f;
}

.sr-bb .srt-base-1-away-5 {
	background-color: transparent;
	border-color: #d57878;
	color: #d57878;
}

.sr-bb .srt-base-1-background {
	background-color: #373737;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-2 {
	background-color: #373737;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-2-is-active {
	background-color: #473636;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-2-is-hoverable:hover {
	background-color: #473636;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-3 {
	background-color: #473636;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-3-is-active {
	background-color: #4e3636;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-3-is-hoverable:hover {
	background-color: #4e3636;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-3-background {
	background-color: #373737;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-4 {
	background-color: #3e3737;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-5-is-active {
	background-color: #5a3535;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-5-is-hoverable:hover {
	background-color: #5a3535;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-base-6 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 30%);
	color: #fff;
}

.sr-bb .srt-primary-1 {
	background-color: #be3030;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-1-is-active {
	background-color: #c54545;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-1-is-hoverable:hover {
	background-color: #c54545;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-1-is-disabled {
	background-color: #cb5959;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-2 {
	background-color: #d26e6e;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-3 {
	background-color: #c54545;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-4 {
	background-color: #833;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-5 {
	background-color: #833;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-5-is-hoverable:hover {
	background-color: #be3030;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-6 {
	background-color: #d98787;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-7 {
	background-color: #be3030;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-8 {
	background-color: #be3030;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-8-is-active-1 {
	background-color: #c54545;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-8-is-active-2 {
	background-color: #c54545;
	border-color: #373737;
	color: #fff;
}

.sr-bb .srt-primary-9 {
	background-color: #603535;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-primary-10 {
	background-color: #a33131;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-11 {
	background-color: #c54545;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-primary-12 {
	background-color: #be3030;
	border-color: #fff;
	color: #fff;
}

.sr-bb .srt-primary-13 {
	background-color: #3e3737;
	border-color: rgba(190, 48, 48, 30%);
	color: #fff;
}

.sr-bb .srt-base-1-primary-1 {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-primary-2 {
	background-color: transparent;
	border-color: #d26e6e;
	color: #d26e6e;
}

.sr-bb .srt-base-1-primary-3 {
	background-color: transparent;
	border-color: #c54545;
	color: #c54545;
}

.sr-bb .srt-base-1-primary-4 {
	background-color: transparent;
	border-color: #833;
	color: #833;
}

.sr-bb .srt-base-1-primary-5 {
	background-color: transparent;
	border-color: #833;
	color: #833;
}

.sr-bb .srt-base-1-primary-6 {
	background-color: transparent;
	border-color: #d98787;
	color: #d98787;
}

.sr-bb .srt-base-1-primary-7 {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-primary-8 {
	background-color: transparent;
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-primary-9 {
	background-color: transparent;
	border-color: #603535;
	color: #603535;
}

.sr-bb .srt-base-1-primary-10 {
	background-color: transparent;
	border-color: #a33131;
	color: #a33131;
}

.sr-bb .srt-base-1-primary-11 {
	background-color: transparent;
	border-color: #c54545;
	color: #c54545;
}

.sr-bb .srt-base-1-primary-13 {
	background-color: #373737;
	border-color: rgba(190, 48, 48, 30%);
	color: #3e3737;
}

.sr-bb .srt-base-1-neutral-1 {
	background-color: transparent;
	border-color: #515151;
	color: #515151;
}

.sr-bb .srt-base-1-neutral-2 {
	background-color: transparent;
	border-color: #ebebeb;
	color: #ebebeb;
}

.sr-bb .srt-base-1-neutral-3 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 12%);
	color: rgba(255, 255, 255, 12%);
}

.sr-bb .srt-base-1-neutral-4 {
	background-color: transparent;
	border-color: #b9b9b9;
	color: #b9b9b9;
}

.sr-bb .srt-base-1-neutral-5 {
	background-color: transparent;
	border-color: #cdcdcd;
	color: #cdcdcd;
}

.sr-bb .srt-base-1-neutral-6 {
	background-color: transparent;
	border-color: #676767;
	color: #676767;
}

.sr-bb .srt-base-1-neutral-7 {
	background-color: transparent;
	border-color: #b9b9b9;
	color: #b9b9b9;
}

.sr-bb .srt-base-1-neutral-8 {
	background-color: transparent;
	border-color: #515151;
	color: #515151;
}

.sr-bb .srt-base-1-neutral-9 {
	background-color: transparent;
	border-color: #434343;
	color: #434343;
}

.sr-bb .srt-base-1-neutral-10 {
	background-color: transparent;
	border-color: #4f4f4f;
	color: #4f4f4f;
}

.sr-bb .srt-base-1-neutral-11 {
	background-color: transparent;
	border-color: #878787;
	color: #878787;
}

.sr-bb .srt-base-1-neutral-12 {
	background-color: transparent;
	border-color: #c3c3c3;
	color: #c3c3c3;
}

.sr-bb .srt-base-1-neutral-13 {
	background-color: transparent;
	border-color: #4b4b4b;
	color: #4b4b4b;
}

.sr-bb .srt-base-1-is-active-primary {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-home-1 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #212121;
	color: #212121;
}

.sr-bb .srt-base-1-is-active-away-1 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-home-2 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #d98787;
	color: #212121;
}

.sr-bb .srt-base-1-is-active-away-2 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #d98787;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-home-3 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: #212121;
}

.sr-bb .srt-base-1-is-active-away-3 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-home-4 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #282828;
	color: #282828;
}

.sr-bb .srt-base-1-is-active-away-4 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #963232;
	color: #963232;
}

.sr-bb .srt-base-1-is-active-home-5 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #6f6f6f;
	color: #6f6f6f;
}

.sr-bb .srt-base-1-is-active-away-5 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #d57878;
	color: #d57878;
}

.sr-bb .srt-base-1-is-active-primary-1 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-primary-2 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #d26e6e;
	color: #d26e6e;
}

.sr-bb .srt-base-1-is-active-primary-3 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #c54545;
	color: #c54545;
}

.sr-bb .srt-base-1-is-active-primary-4 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #833;
	color: #833;
}

.sr-bb .srt-base-1-is-active-primary-5 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #833;
	color: #833;
}

.sr-bb .srt-base-1-is-active-primary-6 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #d98787;
	color: #d98787;
}

.sr-bb .srt-base-1-is-active-primary-7 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-primary-8 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #be3030;
	color: #be3030;
}

.sr-bb .srt-base-1-is-active-primary-9 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #603535;
	color: #603535;
}

.sr-bb .srt-base-1-is-active-primary-10 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #a33131;
	color: #a33131;
}

.sr-bb .srt-base-1-is-active-primary-11 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #c54545;
	color: #c54545;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #515151;
	color: #515151;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #ebebeb;
	color: #ebebeb;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: rgba(255, 255, 255, 12%);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #b9b9b9;
	color: #b9b9b9;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #cdcdcd;
	color: #cdcdcd;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #676767;
	color: #676767;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #b9b9b9;
	color: #b9b9b9;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #515151;
	color: #515151;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #434343;
	color: #434343;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #4f4f4f;
	color: #4f4f4f;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #878787;
	color: #878787;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #c3c3c3;
	color: #c3c3c3;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
	background-color: rgba(190, 48, 48, 12%);
	border-color: #4b4b4b;
	color: #4b4b4b;
}

.sr-bb .srt-home-1 {
	background-color: #212121;
	border-color: #212121;
	color: #fff;
}

.sr-bb .srt-away-1 {
	background-color: #be3030;
	border-color: #be3030;
	color: #fff;
}

.sr-bb .srt-home-2 {
	background-color: #212121;
	border-color: #d98787;
	color: #fff;
}

.sr-bb .srt-away-2 {
	background-color: #be3030;
	border-color: #d98787;
	color: #fff;
}

.sr-bb .srt-home-3 {
	background-color: #212121;
	border-color: #373737;
	color: #fff;
}

.sr-bb .srt-away-3 {
	background-color: #be3030;
	border-color: #373737;
	color: #fff;
}

.sr-bb .srt-home-4 {
	background-color: #282828;
	border-color: #282828;
	color: #fff;
}

.sr-bb .srt-away-4 {
	background-color: #963232;
	border-color: #963232;
	color: #fff;
}

.sr-bb .srt-home-5 {
	background-color: #6f6f6f;
	border-color: #6f6f6f;
	color: #fff;
}

.sr-bb .srt-away-5 {
	background-color: #d57878;
	border-color: #d57878;
	color: #fff;
}

.sr-bb .srt-home-6 {
	background-color: rgba(33, 33, 33, 20%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-away-6 {
	background-color: rgba(190, 48, 48, 20%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-home-6-is-hoverable:hover {
	background-color: rgba(33, 33, 33, 20%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-away-6-is-hoverable:hover {
	background-color: rgba(190, 48, 48, 20%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-1 {
	background-color: #515151;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-2 {
	background-color: #ebebeb;
	border-color: rgba(255, 255, 255, 16%);
	color: #000;
}

.sr-bb .srt-neutral-3 {
	background-color: rgba(255, 255, 255, 12%);
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-4 {
	background-color: #b9b9b9;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-5 {
	background-color: #cdcdcd;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-6 {
	background-color: #676767;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-7 {
	background-color: #b9b9b9;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-8 {
	background-color: #515151;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-9 {
	background-color: #434343;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-10 {
	background-color: #4f4f4f;
	border-color: rgba(255, 255, 255, 12%);
	color: rgba(255, 255, 255, 82%);
}

.sr-bb .srt-neutral-11 {
	background-color: #878787;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-neutral-12 {
	background-color: #c3c3c3;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-13 {
	background-color: #4b4b4b;
	border-color: rgba(255, 255, 255, 12%);
	color: #fff;
}

.sr-bb .srt-win {
	background-color: #212121;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-draw {
	background-color: rgba(255, 255, 255, 40%);
	border-color: rgba(255, 255, 255, 16%);
	color: #000;
}

.sr-bb .srt-lose {
	background-color: #be3030;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.8;
}

.sr-bb .srt-text-disabled {
	opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
	opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary,
.sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.8;
}

.sr-bb .srt-primary-1 .srt-text-disabled,
.sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
	opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary,
.sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
	opacity: 0.7;
}

.sr-bb .srt-icon {
	opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
	opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%), 0 1px 3px 0 rgba(0, 0, 0, 10%);
}

.sr-bb .srt-elevation-2 {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 23%), 0 3px 6px 0 rgba(0, 0, 0, 16%);
}

.sr-bb .srt-elevation-3 {
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 26%), 0 10px 20px 0 rgba(0, 0, 0, 19%);
}

.sr-bb .srt-elevation-center-2 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 23%), 0 1px 5px rgba(0, 0, 0, 16%);
}

.sr-bb .srt-inset-top-1 {
	box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-1 {
	box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-2 {
	box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-2 {
	box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-3 {
	box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-3 {
	box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-fill-blue-card {
	fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
	stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
	fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
	stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
	fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
	stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
	stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
	fill: transparent;
	stroke: #4fbe30;
}

.sr-bb .srt-fill-soccer-substitution-in {
	fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-substitution-out {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-own-goal {
	fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
	fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
	stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
	fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
	stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
	fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
	stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
	fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
	stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
	fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
	stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
	fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
	stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
	fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
	stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
	fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
	stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
	fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
	stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
	fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
	stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
	background-color: #f5a623;
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-nfl-timeout-2 {
	background-color: rgba(245, 166, 35, 30%);
	border-color: rgba(255, 255, 255, 16%);
	color: #fff;
}

.sr-bb .srt-nfl-penalty {
	background-color: #f4a621;
	border-color: #f4a621;
	color: #fff;
}

.sr-bb .srt-nfl-negative-yards-1 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #fff;
}

.sr-bb .srt-nfl-negative-yards-2 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #fff;
}

.sr-bb .srt-nfl-first-line {
	background-color: #4b90de;
	border-color: #4b90de;
	color: #fff;
}

.sr-bb .srt-nfl-ten-line {
	background-color: #f5a623;
	border-color: #f5a623;
	color: #fff;
}

.sr-bb .srt-fill-nfl-penalty {
	fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
	stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
	fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
	stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
	fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
	stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
	background-color: #090;
	border-color: #090;
	color: #fff;
}

.sr-bb .srt-mlb-run-2 {
	background-color: #fff;
	border-color: #090;
	color: #090;
}

.sr-bb .srt-mlb-hit-1 {
	background-color: #f7ab11;
	border-color: #f7ab11;
	color: #fff;
}

.sr-bb .srt-mlb-hit-2 {
	background-color: #fff;
	border-color: #f7ab11;
	color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
	background-color: #00a4ff;
	border-color: #00a4ff;
	color: #fff;
}

.sr-bb .srt-mlb-error-1 {
	background-color: #d0021b;
	border-color: #d0021b;
	color: #fff;
}

.sr-bb .srt-mlb-error-2 {
	background-color: #fff;
	border-color: #d0021b;
	color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
	background-color: #212121;
	border-color: #fff;
	color: #fff;
}

.sr-bb .srt-fill-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
	fill: #212121;
}

.sr-bb .srt-stroke-change-increase {
	stroke: #212121;
}

.sr-bb .srt-fill-change-decrease {
	fill: #be3030;
}

.sr-bb .srt-stroke-change-decrease {
	stroke: #be3030;
}

.sr-bb .srt-fill-text {
	fill: #fff;
}

.sr-bb .srt-fill-text-invert {
	fill: #fff;
}

.sr-bb .srt-fill-text-secondary {
	fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
	fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
	fill: rgba(190, 48, 48, 12%);
}

.sr-bb .srt-stroke-base-1-active {
	stroke: rgba(190, 48, 48, 12%);
}

.sr-bb .srt-fill-base-1-active-2 {
	fill: #4b4b4b;
}

.sr-bb .srt-stroke-base-1-active-2 {
	stroke: #4b4b4b;
}

.sr-bb .srt-fill-base-1-primary {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
	stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
	fill: currentcolor;
}

.sr-bb .srt-stroke-base-2 {
	stroke: currentcolor;
}

.sr-bb .srt-fill-base-2-active {
	fill: #473636;
}

.sr-bb .srt-stroke-base-2-active {
	stroke: #473636;
}

.sr-bb .srt-fill-base-2-hover {
	fill: #473636;
}

.sr-bb .srt-stroke-base-2-hover {
	stroke: #473636;
}

.sr-bb .srt-fill-base-3 {
	fill: #473636;
}

.sr-bb .srt-stroke-base-3 {
	stroke: #473636;
}

.sr-bb .srt-fill-base-3-active {
	fill: #4e3636;
}

.sr-bb .srt-stroke-base-3-active {
	stroke: #4e3636;
}

.sr-bb .srt-fill-base-3-hover {
	fill: #4e3636;
}

.sr-bb .srt-stroke-base-3-hover {
	stroke: #4e3636;
}

.sr-bb .srt-fill-primary-1 {
	fill: #be3030;
}

.sr-bb .srt-stroke-primary-1 {
	stroke: #be3030;
}

.sr-bb .srt-fill-primary-2 {
	fill: #d26e6e;
}

.sr-bb .srt-stroke-primary-2 {
	stroke: #d26e6e;
}

.sr-bb .srt-fill-primary-3 {
	fill: #c54545;
}

.sr-bb .srt-stroke-primary-3 {
	stroke: #c54545;
}

.sr-bb .srt-fill-primary-4 {
	fill: #833;
}

.sr-bb .srt-stroke-primary-4 {
	stroke: #833;
}

.sr-bb .srt-fill-primary-5 {
	fill: #833;
}

.sr-bb .srt-stroke-primary-5 {
	stroke: #833;
}

.sr-bb .srt-fill-primary-6 {
	fill: #d98787;
}

.sr-bb .srt-stroke-primary-6 {
	stroke: #d98787;
}

.sr-bb .srt-fill-primary-7 {
	fill: #be3030;
}

.sr-bb .srt-stroke-primary-7 {
	stroke: #be3030;
}

.sr-bb .srt-fill-primary-8 {
	fill: #be3030;
}

.sr-bb .srt-stroke-primary-8 {
	stroke: #be3030;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
	fill: #c54545;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
	stroke: #c54545;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
	fill: #c54545;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
	stroke: #c54545;
}

.sr-bb .srt-fill-primary-9 {
	fill: #603535;
}

.sr-bb .srt-stroke-primary-9 {
	stroke: #603535;
}

.sr-bb .srt-fill-primary-10 {
	fill: #a33131;
}

.sr-bb .srt-stroke-primary-10 {
	stroke: #a33131;
}

.sr-bb .srt-fill-primary-11 {
	fill: #c54545;
}

.sr-bb .srt-stroke-primary-11 {
	stroke: #c54545;
}

.sr-bb .srt-fill-primary-12 {
	fill: #be3030;
}

.sr-bb .srt-stroke-primary-12 {
	stroke: #be3030;
}

.sr-bb .srt-fill-home-1 {
	fill: #212121;
}

.sr-bb .srt-stroke-home-1 {
	stroke: #212121;
}

.sr-bb .srt-fill-home-2 {
	fill: #212121;
}

.sr-bb .srt-stroke-home-2 {
	stroke: #212121;
}

.sr-bb .srt-fill-home-3 {
	fill: #212121;
}

.sr-bb .srt-stroke-home-3 {
	stroke: #212121;
}

.sr-bb .srt-fill-home-4 {
	fill: #282828;
}

.sr-bb .srt-stroke-home-4 {
	stroke: #282828;
}

.sr-bb .srt-fill-home-5 {
	fill: #6f6f6f;
}

.sr-bb .srt-stroke-home-5 {
	stroke: #6f6f6f;
}

.sr-bb .srt-fill-away-1 {
	fill: #be3030;
}

.sr-bb .srt-stroke-away-1 {
	stroke: #be3030;
}

.sr-bb .srt-fill-away-2 {
	fill: #be3030;
}

.sr-bb .srt-stroke-away-2 {
	stroke: #be3030;
}

.sr-bb .srt-fill-away-3 {
	fill: #be3030;
}

.sr-bb .srt-stroke-away-3 {
	stroke: #be3030;
}

.sr-bb .srt-fill-away-4 {
	fill: #963232;
}

.sr-bb .srt-stroke-away-4 {
	stroke: #963232;
}

.sr-bb .srt-fill-away-5 {
	fill: #d57878;
}

.sr-bb .srt-stroke-away-5 {
	stroke: #d57878;
}

.sr-bb .srt-fill-neutral-1 {
	fill: #515151;
}

.sr-bb .srt-stroke-neutral-1 {
	stroke: #515151;
}

.sr-bb .srt-fill-neutral-2 {
	fill: #ebebeb;
}

.sr-bb .srt-stroke-neutral-2 {
	stroke: #ebebeb;
}

.sr-bb .srt-fill-neutral-3 {
	fill: rgba(255, 255, 255, 12%);
}

.sr-bb .srt-stroke-neutral-3 {
	stroke: rgba(255, 255, 255, 12%);
}

.sr-bb .srt-fill-neutral-4 {
	fill: #b9b9b9;
}

.sr-bb .srt-stroke-neutral-4 {
	stroke: #b9b9b9;
}

.sr-bb .srt-fill-neutral-5 {
	fill: #cdcdcd;
}

.sr-bb .srt-stroke-neutral-5 {
	stroke: #cdcdcd;
}

.sr-bb .srt-fill-neutral-6 {
	fill: #676767;
}

.sr-bb .srt-stroke-neutral-6 {
	stroke: #676767;
}

.sr-bb .srt-fill-neutral-7 {
	fill: #b9b9b9;
}

.sr-bb .srt-stroke-neutral-7 {
	stroke: #b9b9b9;
}

.sr-bb .srt-fill-neutral-8 {
	fill: #515151;
}

.sr-bb .srt-stroke-neutral-8 {
	stroke: #515151;
}

.sr-bb .srt-fill-neutral-9 {
	fill: #434343;
}

.sr-bb .srt-stroke-neutral-9 {
	stroke: #434343;
}

.sr-bb .srt-fill-neutral-10 {
	fill: #4f4f4f;
}

.sr-bb .srt-stroke-neutral-10 {
	stroke: #4f4f4f;
}

.sr-bb .srt-fill-neutral-11 {
	fill: #878787;
}

.sr-bb .srt-stroke-neutral-11 {
	stroke: #878787;
}

.sr-bb .srt-fill-neutral-12 {
	fill: #c3c3c3;
}

.sr-bb .srt-stroke-neutral-12 {
	stroke: #c3c3c3;
}

.sr-bb .srt-fill-neutral-13 {
	fill: #4b4b4b;
}

.sr-bb .srt-stroke-neutral-13 {
	stroke: #4b4b4b;
}

.sr-bb .srt-fill-win {
	fill: #212121;
}

.sr-bb .srt-stroke-win {
	stroke: #212121;
}

.sr-bb .srt-fill-draw {
	fill: rgba(255, 255, 255, 40%);
}

.sr-bb .srt-stroke-draw {
	stroke: rgba(255, 255, 255, 40%);
}

.sr-bb .srt-fill-lose {
	fill: #be3030;
}

.sr-bb .srt-stroke-lose {
	stroke: #be3030;
}

.sr-bb .srt-stop-base-1 {
	stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
	stop-color: #be3030;
}

.sr-bb .srt-stop-primary-2 {
	stop-color: #d26e6e;
}

.sr-bb .srt-stop-primary-3 {
	stop-color: #c54545;
}

.sr-bb .srt-stop-primary-4 {
	stop-color: #833;
}

.sr-bb .srt-stop-primary-5 {
	stop-color: #833;
}

.sr-bb .srt-stop-primary-6 {
	stop-color: #d98787;
}

.sr-bb .srt-stop-primary-7 {
	stop-color: #be3030;
}

.sr-bb .srt-stop-primary-8 {
	stop-color: #be3030;
}

.sr-bb .srt-stop-primary-9 {
	stop-color: #603535;
}

.sr-bb .srt-stop-primary-10 {
	stop-color: #a33131;
}

.sr-bb .srt-stop-primary-11 {
	stop-color: #c54545;
}

.sr-bb .srt-stop-primary-12 {
	stop-color: #be3030;
}

.sr-bb .srt-stop-home-1 {
	stop-color: #212121;
}

.sr-bb .srt-stop-away-1 {
	stop-color: #be3030;
}

.sr-bb .srt-fill-neutral-14 {
	fill: #373737;
}

.sr-bb .srt-stroke-neutral-14 {
	stroke: #373737;
}

.sr-bb .srt-logo-powered-by-light {
	display: none;
}

.sr-bb .srt-logo-powered-by-dark {
	display: inline-block;
}
