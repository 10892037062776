@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-light), 1);
	font-family: 'Nunito Sans', sans-serif;
}

body {
	background-color: rgba(var(--base-09), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-grey-dark), 1);

		h2 {
			color: rgba(var(--font-light), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-red-light), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/bonus-active

.menu {
	.page-head {
		&.activity-header {
			background-color: rgba(var(--base-10), 1);
		}
	}

	&.activity-item-list-container {
		.activity-bg {
			background-color: rgba(var(--base-10), 1);
		}

		.support-title {
			color: rgba(var(--font-light), 1);
		}
	}
}

.progress {
	svg {
		path {
			stroke: rgba(var(--base-01), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-green), 1);
		}
	}

	.progress__number {
		color: rgba(var(--base-01), 1);

		* {
			color: inherit;
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-red), 1);
	color: rgba(var(--base-01), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-grey), 1);
		color: rgba(var(--base-04), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--base-08), 1);
	}

	&.btn--tertiary {
		background: none;
		color: rgba(var(--font-light), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-red-light), 1);
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--base-02), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--base-10), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--font-light), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-red), 1);
		color: rgba(var(--primary-accent-red), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(30%) sepia(1) hue-rotate(30deg) saturate(22);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--base-10), 1);
	border-bottom: solid 1px rgba(var(--primary-accent-grey-light), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--primary-accent-red), 1);
			color: rgba(var(--base-01), 1);
		}
	}

	.sliding-border {
		background-color: rgba(var(--base-01), 1);
		height: 3px;
	}
}

// components/flags
// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #212121 !important;
	-webkit-text-fill-color: #fff !important;
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);

		&:focus {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-05), 1);
	}

	::-webkit-calendar-picker-indicator {
		filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(168deg) brightness(104%) contrast(101%);
	}
}

.slider-icon {
	.icon--sprite {
		fill: rgba(var(--base-01), 1);
	}
}

::-webkit-input-placeholder {
	// color: rgba(var(--base-07), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--base-07), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--base-07), 1);
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--input-border-color), 1);
	}
}

// components/game-category
// components/game-item

.item__urgent {
	background-color: rgba(var(--primary-accent-green-dark), 1);

	* {
		color: rgba(var(--base-01), 1);
	}
}

// components/game-list
// components/header

.page-head {
	background-color: rgba(var(--base-10), 1);

	.btn--login {
		color: rgba(var(--base-02), 1);
	}

	.page-head__title {
		color: rgba(var(--base-02), 1);
	}

	svg {
		fill: rgba(var(--base-02), 1);
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--base-01), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}

		.btn--login {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	&.country-select-header {
		.page-head__title {
			color: rgba(var(--base-01), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--base-08), 1);

		&--rounded {
			border-radius: 3px;
		}

		svg {
			fill: rgba(var(--font-light), 1);
		}

		.menu__main-title {
			color: rgba(var(--font-light), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--font-light), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__preamble,
		.link-list__value {
			color: rgba(var(--font-light), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--font-light), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--font-light), 1);
		}
	}

	&.link-list--flip-colors {
		.link-list__item {
			background-color: rgba(var(--base-08), 1);

			svg {
				fill: rgba(var(--base-01), 1);
			}

			.link-list__item-link {
				span {
					color: rgba(var(--base-01), 1);
				}
			}

			.link-list__value {
				color: rgba(var(--base-01), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--base-01), 1);
			}

			.link-list__status-verified {
				color: rgba(var(--primary-accent-green), 1);
			}

			.link-list__status-unverified {
				color: rgba(var(--base-10), 1);

				svg {
					fill: rgba(var(--base-10), 1);
				}
			}
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--base-10), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);

			.link-list__continue-icon {
				fill: rgba(var(--base-01), 1);
			}
		}
	}

	.page-head {
		background-color: inherit;
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

.my-notification-item-wrapper,
.my-notification-details,
.my-notification-title-bar,
.my-notification-title {
	color: rgba(var(--font-light), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);
	}

	.binary-question__button-container {
		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
		}
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX

// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT

// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-grey), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--tertiary), 1);
			}
		}
	}
}

.js-notify__button-wrapper--failed {
	svg {
		fill: rgba(var(--tertiary), 1);
	}
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--base-05), 1);

	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-grey-dark), 1) !important;

		.button-wrapper .btn {
			margin-top: 8px;
		}
	}
}

.option-box {
	background-color: rgba(var(--base-05), 1);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
	}

	.btn--secondary {
		background-color: rgba(var(--base-05), 1);
		color: rgba(var(--base-10), 1);
	}

	.user-options {
		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--base-10), 1);
					color: rgba(var(--base-02), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-green), 1);
					}
				}
			}
		}
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-grey), 1);
	}

	.binary-question__main-title {
		color: rgba(var(--font-light), 1);
	}

	.binary-question__p,
	.input-wrap__input,
	.two-fact__input-wrap input {
		color: rgba(var(--font-supportive), 1);
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--primary-accent-grey-light), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-light), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--primary-accent-grey-light), 1);
		border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);
	}
}

.quick-deposit-wrapper {
	background-color: rgba(var(--base-10), 1);
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/scroll-page-head

header.page-head {
	&.page-head--opac {
		background-color: rgba(var(--base-10), 1);

		.page-head__title {
			color: rgba(var(--base-01), 1);
		}

		.btn--skip {
			color: rgba(var(--base-01), 1);
		}
	}
}

// components/select

.custom-select {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);

	select {
		color: rgba(var(--base-01), 1);
	}

	option {
		color: rgba(var(--base-10), 1);
	}

	select.placeholder-active {
		color: rgba(var(--base-07), 1);
	}

	&::after {
		filter: brightness(0) invert(1);
	}
}

// components/spinner
// components/support-nav

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.support-nav {
	.support-nav__head {
		.support-nav__main-title {
			color: rgba(var(--font-light), 1);
		}
	}

	.btn--secondary {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--font-light), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}
}

// components/tabs

.tab {
	* {
		color: rgba(var(--font-supportive), 1);
	}

	[aria-selected='true'] {
		span {
			color: var(--product-tab-selected, rgba(var(--primary-accent-red), 1));
		}

		svg {
			fill: var(--product-tab-selected, rgba(var(--primary-accent-red), 1));
		}
	}
}

// components/winners
// layouts/account-my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		background-color: rgba(var(--base-08), 1);

		.icon-close--small {
			.icon--sprite {
				fill: rgba(var(--base-01), 1);
			}
		}
	}
}

.time__limits-suggested_title {
	color: rgba(var(--primary-accent-green), 1);
}

// layouts/bundles

//layouts/bonus terms & condition
.bonus-terms-wrapper {
	.terms-header__text-color {
		color: rgba(var(--font-light), 1);
	}
}

// layouts/deposit

.bonus-item__image-wrapper {
	background-color: rgba(var(--blue-900), 1);
}

// layouts/footer

.paragraph-footer,
.footer-legal-content {
	color: rgba(var(--footer-text), 1);

	a {
		color: rgba(var(--primary-accent-red-light), 1);
	}
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/info-box
.resp-gaming-account {
	&.session-timer {
		&.info-box {
			background-color: rgba(var(--base-08), 1);

			.info-box-value {
				color: rgba(var(--font-light), 1);
			}

			.info-box-text {
				color: rgba(var(--font-supportive), 1);
			}
		}
	}
}

// layouts/log-in and reg
// layouts/modal-view
.registration-basic-continue-button {
	text-transform: uppercase;
}

.modal-view {
	background-color: rgba(var(--base-10), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

//this is to fix color issue on some of the brands logos displaying wrong color under our cookie-settings head
.cookie-settings-header {
	.svg-logo {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-05), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-05), 1);
		}
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	background-color: rgba(var(--base-10), 1);
}

.account-menu {
	.page-head {
		background-color: inherit;

		svg {
			fill: rgba(var(--base-02), 1);
		}

		.page-head__title {
			color: rgba(var(--base-02), 1);
		}
	}
}

.account-icon-header {
	color: rgba(var(--base-02), 1);
}

// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--base-02), 1);
	}

	.user-balance__sum {
		color: rgba(var(--base-02), 1);
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
// layouts/pusher
// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.imoje-banks {
	fill: none;
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.selected-bank svg {
	border: 4px solid rgba(var(--primary-accent-red));
	border-radius: 3px;
}

.result {
	&.result--is-open {
		.nfs-bg svg {
			fill: rgba(var(--base-10), 1);
		}

		.failed-deposit {
			*:not(.btn),
			.btn- {
				color: rgba(var(--base-10), 1);
				filter: rgba(var(--base-10), 1);
			}

			svg:not(.logo-payment-method) {
				fill: rgba(var(--base-10), 1);
			}

			.payment-result__icon img,
			.payment-methods__item__chevron img {
				filter: var(--filter-primary);
			}
		}

		.js-try-again-button-header {
			color: rgba(var(--base-10), 1);
		}
	}

	&.result--info {
		background-color: rgba(var(--info), 1) !important;
		background-image: none;

		* {
			color: rgba(var(--base-02), 1);
			fill: rgba(var(--base-02), 1);
		}

		.btn--secondary {
			background-color: rgba(#3099ea, 1);
			color: rgba(var(--base-02), 1);
		}
	}
}

// layouts/risk

.risk-tag {
	background-color: rgba(var(--base-05), 1);
}

// layouts/shop

.shop-success__head {
	.icon--sprite {
		fill: rgba(var(--base-01), 1);
	}
}

.points__svg {
	fill: rgba(var(--primary-accent-green), 1);

	text {
		fill: rgba(var(--base-02), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--base-05), 1);

	&.signup--open,
	&.log-in--open {
		background-color: initial;
	}
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	background-color: rgba(var(--base-09), 1);

	.adaptable-header--pinned {
		--page-head-title: transparent;
		--page-head-link: rgba(var(--primary-accent-red-light), 1);
		--page-head-icon: rgba(var(--base-01), 1);

		background-color: rgba(var(--base-09), 1);
		border-bottom: transparent;
	}

	.adaptable-header--unfixed {
		--page-head-title: var(--main-title);
		--page-head-link: rgba(var(--primary-accent-red-light), 1);
		--page-head-icon: rgba(var(--base-01), 1);

		background-color: rgba(var(--base-09), 1);
		border-bottom: transparent;
	}
}

.static-text {
	&.terms-conditions {
		.page-head {
			background-color: rgba(var(--base-10), 1);

			&.u-bg-trans {
				background-color: var(--sidebar-bg);
			}
		}
	}

	.brand-logo {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

// layouts/titles
// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--overlay), 0.8);
}

.transaction-modal {
	background-color: rgba(var(--base-10), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/welcome-screen

.welcome-menu {
	background-color: rgba(var(--primary-accent-green), 1);

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-05), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-05), 1);

			tr {
				border-bottom: 1px solid rgba(var(--base-01), 1);
				color: rgba(var(--base-02), 1);
			}
		}
	}
}

.welcome_animation {
	path {
		fill: rgba(var(--base-02), 1);
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid var(--primary-accent-grey-dark);

	.pending-withdrawal__action {
		.pending-withdrawal__cancel-icon {
			fill: rgba(var(--primary-accent-red-dark), 1);
		}
	}

	.pending-withdrawal__method,
	.pending-withdrawal__amount {
		color: rgba(var(--primary-accent-red), 1);
	}
}

// layouts/SEO content

.seo-content {
	.seo-content__color,
	* {
		color: rgba(var(--font-light), 1);
	}

	a {
		color: rgba(var(--primary-accent-red-light), 1);
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-01), 1);
}

.u-divider {
	border-color: rgba(var(--base-05), 1);
}

// Responsible Gaming

// Risk-profile

// Risk-profile

.risk-profile__description {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

// Legal Bookie Logo

.cls-1 {
	fill: #dcb362;
}

.cls-11 {
	fill: var(--footer-payment-icon, rgba(var(--base-02), 1));
}

.cls-2 {
	fill: var(--footer-bg, rgba(var(--base-10), 1));
}

//components/sesson-timer

.session-timer {
	span {
		color: rgba(var(--base-01), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--base-01), 1);
	}
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	color: rgba(var(--font-light), 1);

	&__popular {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-red), 1);
	}
}

.disclaimer-text {
	color: rgba(var(--primary-accent-red-light), 1);
	text-decoration: underline;
}
// Transaction History
.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}
